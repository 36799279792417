import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createTextVNode as _createTextVNode, Suspense as _Suspense, withCtx as _withCtx, Transition as _Transition, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "header-right-container d-flex align-center mr-4 ml-4" }
const _hoisted_3 = { class: "header-left-container d-flex align-center mr-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ConnectStatus = _resolveComponent("ConnectStatus")!
  const _component_Socials = _resolveComponent("Socials")!
  const _component_SpoilerEye = _resolveComponent("SpoilerEye")!
  const _component_Menu = _resolveComponent("Menu")!
  const _component_RouterView = _resolveComponent("RouterView")!
  const _component_Settings = _resolveComponent("Settings")!
  const _component_InfoSnackbar = _resolveComponent("InfoSnackbar")!
  const _component_Version = _resolveComponent("Version")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("header", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_ConnectStatus, { class: "connect-status mr-4" }),
        _createVNode(_component_Socials)
      ]),
      _createElementVNode("div", _hoisted_3, [
        (_ctx.currentRoute === 'room')
          ? (_openBlock(), _createBlock(_component_SpoilerEye, { key: 0 }))
          : _createCommentVNode("", true),
        _createVNode(_component_Menu, { onProfileClick: _ctx.profileClick }, null, 8, ["onProfileClick"])
      ])
    ]),
    _createVNode(_component_RouterView, null, {
      default: _withCtx(({ Component }) => [
        Component
          ? (_openBlock(), _createBlock(_Transition, {
              key: 0,
              mode: "out-in"
            }, {
              default: _withCtx(() => [
                (_openBlock(), _createBlock(_Suspense, null, {
                  fallback: _withCtx(() => [
                    _createTextVNode(" Loading... ")
                  ]),
                  default: _withCtx(() => [
                    (_openBlock(), _createBlock(_resolveDynamicComponent(Component), { class: "page" }))
                  ]),
                  _: 2
                }, 1024))
              ]),
              _: 2
            }, 1024))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_Settings, { ref: "settings" }, null, 512),
    _createVNode(_component_InfoSnackbar),
    _createVNode(_component_Version, { class: "version" })
  ], 64))
}